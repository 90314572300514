import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {flatMap} from 'rxjs/operators';
import {IResponse} from '../interfaces/response';
import {of} from 'rxjs';
import {Router} from "@angular/router";

export interface BadgeItem {
  type: string;
  value: string;
  roles?: string[];
}

export interface Separator {
  name: string;
  type?: string;
  roles?: string[];
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
  roles?: string[];
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
  roles?: string[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
  roles?: string[];
}

const MENUITEMS = [
  {
    state: 'general',
    name: 'General',
    type: 'link',
    roles: ['OWNER', 'ACCOUNTANT'],
    icon: 'fiber_manual_record',
  },
];

@Injectable()
export class MenuItems {
  private static _generateUrl() {
    return environment.api_base_url + '/me/roles';
  }

  private static _generateParams() {
    return new HttpParams();
  }

  private static _hasRole(userRoles, menuRoles) {
    if (userRoles && userRoles.indexOf('*') >= 0) {
      return true;
    }
    if (menuRoles && menuRoles.indexOf('*') >= 0) {
      return true;
    }
    if (!menuRoles || !userRoles) {
      return false;
    }

    return userRoles.filter(item => menuRoles.indexOf(item) >= 0).length > 0
  }

  public menuItems = [];

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
  }

  public refreshMenuItem() {
    const requestUrl = MenuItems._generateUrl();
    const requestParams: HttpParams = MenuItems._generateParams();
    this.menuItems = [];

    return this.httpClient.get<any>(requestUrl,
      {params: requestParams}
    ).pipe(
      flatMap((res: IResponse) => {
        let roles: string[] = res.data.roles;
        const self = this;
        MENUITEMS.forEach(function (menu: Menu, index, array) {
          const _menu = menu;
          if (menu.type === 'sub') {
            const _children = [];
            const children = menu.children;
            children.forEach(function (child: ChildrenItems, _index, _array) {
              if (MenuItems._hasRole(roles, child.roles)) {
                _children.push(child);
              }
            });
            _menu.children = _children;
          }
          if (MenuItems._hasRole(roles, menu.roles)) {
            self.menuItems.push(_menu);
          }
        });
        return of(null);
      })
    )
  }

  getMenuItem(): Menu[] {
    return this.menuItems;
  }
}
