<div class="login-register">
  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <div class="text-center">
          <h4 class="m-t-10">Choose an account</h4>
        </div>
        <div *ngIf="!data || data?.length == 0" style="text-align: center">
          You have not been authorized into the account
        </div>

        <section *ngFor="let obj of data" layout="row" (click)="chooseAccount(obj.item.slug)">
          <div class="row">
            <div style="font-weight: bold">
              {{obj.item.slug}}
            </div>
            <div>
              {{obj.item.profile.name}}
            </div>
          </div>
          <hr flex/>
        </section>

      </mat-card-content>
    </mat-card>
  </div>
</div>
