import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    auth_token = environment.auth_token;

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    public logout() {
        const url = environment.api_base_url + '/auth/logout';
        return this.httpClient.post(url, null).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

}
