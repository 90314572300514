import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SelectAccountService {
  auth_token = environment.auth_token;
  constructor(
      private httpClient: HttpClient
  ) { }

  public getListAccount(){
    const url =environment.api_base_url + '/account/';
    return this.httpClient.get(url);
  }
}
