import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {AppRoutes} from './app.routing';
import {AppComponent} from './app.component';

import {FlexLayoutModule} from '@angular/flex-layout';
import {FullComponent} from './layouts/full/full.component';
import {AppBlankComponent} from './layouts/blank/blank.component';
import {AppHeaderComponent} from './layouts/full/header/header.component';
import {AppSidebarComponent} from './layouts/full/sidebar/sidebar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AllMaterialModule} from './all-material-module';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

import {SharedModule} from './shared/shared.module';
import {SpinnerComponent} from './shared/spinner.component';
import {authTokenInterceptorProvider} from './authentication/auth-token.interceptor';
import {CookieService} from 'ngx-cookie-service';
import {authInterceptorProvider} from './authentication/auth.interceptor';
import {RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings} from 'ng-recaptcha';
import {NotifierModule, NotifierOptions} from 'angular-notifier';
import {environment} from '../environments/environment';
import {HomeComponent} from './home/home.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppHeaderBlankComponent} from "./layouts/blank/header/header.component";
import {MultiLanguageService} from "./shared/translate/multiLanguageService";
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import { NgOtpInputModule } from  'ng-otp-input';
import { SelectAccountComponent } from './select-account/select-account.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'left',
            distance: 12
        },
        vertical: {
            position: 'bottom',
            distance: 12,
            gap: 10
        }
    },
    theme: 'material',
    behaviour: {
        autoHide: 5000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        AppHeaderComponent,
        AppHeaderBlankComponent,
        SpinnerComponent,
        AppBlankComponent,
        AppSidebarComponent,
        HomeComponent,
        SelectAccountComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AllMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        PerfectScrollbarModule,
        SharedModule,
        NotifierModule.withConfig(customNotifierOptions),
        NgMultiSelectDropDownModule.forRoot(),
        RouterModule.forRoot(AppRoutes),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxMatIntlTelInputModule,
        NgOtpInputModule
    ],
    providers: [
        Title,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        authTokenInterceptorProvider,
        authInterceptorProvider,
        CookieService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.google_captcha_site_key
            } as RecaptchaSettings,
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: environment.google_captcha_language,
        },
        MultiLanguageService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
    ]
})
export class AppModule {
}
