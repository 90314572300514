import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    ViewChild,
    HostListener,
    Directive,
    AfterViewInit,
    OnInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;
    baseHref = environment.base_href;

    private readonly _mobileQueryListener: () => void;
    status = false;
    clickEvent() {
        this.status = !this.status;
    }

    subclickEvent() {
        this.status = true;
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems,
    ) {

        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        if (this.mobileQuery.addEventListener)
            this.mobileQuery.addEventListener('', this._mobileQueryListener);
        else
            this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnDestroy(): void {
        if (this.mobileQuery.removeEventListener)
            this.mobileQuery.removeEventListener('', this._mobileQueryListener);
        else
            this.mobileQuery.removeListener(this._mobileQueryListener);
    }
}
