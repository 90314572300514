import { Component, OnInit, HostBinding } from '@angular/core';
import {Router} from "@angular/router";
import {SelectAccountService} from "./select-account.service";

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.css']
})
export class SelectAccountComponent implements OnInit {
  @HostBinding('class.page-app') is_page_app = true;
  data: any;
  constructor(
      private router: Router,
      private service: SelectAccountService,
  ) { }

  ngOnInit() {
    this.service.getListAccount().subscribe((res) => {
      this.data = res['data']['items'];
      if (res['success']) {
        console.log(this.data);
        console.log(this.data.length);

      }
    });
  }

  chooseAccount(slug) {
    this.router.navigate(["/"+ slug]);
  }

}
